import React, { Component, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Categories = React.lazy(() => import('./views/categories/Categories'))
const Category = React.lazy(() => import('./views/categories/Category'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Login = React.lazy(() => import('./views/auth/Login'))
const Push = React.lazy(() => import('./views/push/EditCampaign'))
const Users = React.lazy(() => import('./views/users/Users'))
const User = React.lazy(() => import('./views/users/User'))
const Promotions = React.lazy(() => import('./views/promotions/Promotions'))
const Promotion = React.lazy(() => import('./views/promotions/Promotion'))
const Companies = React.lazy(() => import('./views/company/Companies'))
const Company = React.lazy(() => import('./views/company/Company'))
const Banners = React.lazy(() => import('./views/banners/Banners'))
const Banner = React.lazy(() => import('./views/banners/Banner'))
const Roles = React.lazy(() => import('./views/roles/Roles'))
const Role = React.lazy(() => import('./views/roles/Role'))
const News = React.lazy(() => import('./views/news/News'))
const EditNews = React.lazy(() => import('./views/news/EditNews'))
const Campaigns = React.lazy(() => import('./views/push/Campaigns'))
const EditCampaign = React.lazy(() => import('./views/push/EditCampaign'))
const TopBrand = React.lazy(() => import('./views/topbrands/EditTopBrands'))
const Parkings = React.lazy(() => import('./views/parking/Parkings'))

// const modalLogoutStatus = useTypedSelector((state) => state.modalLogout)

const App = (): JSX.Element => {
  function RequireAuth({ children }: any) {
    if (!localStorage.access_token) {
      return <Navigate to="/login" />
    }
    return children
  }

  return (
    <Router>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
            <Route
              path="/categories"
              element={
                <RequireAuth>
                  <Categories />
                </RequireAuth>
              }
            />
            <Route
              path="/categories/:id"
              element={
                <RequireAuth>
                  <Category />
                </RequireAuth>
              }
            />
            <Route
              path="/push"
              element={
                <RequireAuth>
                  <Push />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/users/:id"
              element={
                <RequireAuth>
                  <User />
                </RequireAuth>
              }
            />
            <Route
              path="/promotions"
              element={
                <RequireAuth>
                  <Promotions />
                </RequireAuth>
              }
            />
            <Route
              path="/promotions/:id"
              element={
                <RequireAuth>
                  <Promotion />
                </RequireAuth>
              }
            />
            <Route
              path="/companies"
              element={
                <RequireAuth>
                  <Companies />
                </RequireAuth>
              }
            />
            <Route
              path="/companies/:id"
              element={
                <RequireAuth>
                  <Company />
                </RequireAuth>
              }
            />
            <Route
              path="/banners"
              element={
                <RequireAuth>
                  <Banners />
                </RequireAuth>
              }
            />
            <Route
              path="/banners/:id"
              element={
                <RequireAuth>
                  <Banner />
                </RequireAuth>
              }
            />
            <Route
              path="/roles"
              element={
                <RequireAuth>
                  <Roles />
                </RequireAuth>
              }
            />
            <Route
              path="/roles/:id"
              element={
                <RequireAuth>
                  <Role />
                </RequireAuth>
              }
            />
            <Route
              path="/parkings"
              element={
                <RequireAuth>
                  <Parkings />
                </RequireAuth>
              }
            />
            <Route
              path="/news"
              element={
                <RequireAuth>
                  <News />
                </RequireAuth>
              }
            />
            <Route
              path="/news/:id"
              element={
                <RequireAuth>
                  <EditNews />
                </RequireAuth>
              }
            />
            <Route
              path="/campaigns"
              element={
                <RequireAuth>
                  <Campaigns />
                </RequireAuth>
              }
            />
            <Route
              path="/topbrands"
              element={
                <RequireAuth>
                  <TopBrand />
                </RequireAuth>
              }
            />
            <Route
              path="/campaigns/edit"
              element={
                <RequireAuth>
                  <EditCampaign />
                </RequireAuth>
              }
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
